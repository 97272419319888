/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Button } from 'components/buttons';

const ToggleLinkStyled = styled(Button).attrs({
    variant: Button.Variant.LINK,
})`
    display: ${({ expanded }) => (expanded ? 'block' : 'block')};
    padding: 0;
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing[2]};
    vertical-align: baseline;
    background: transparent;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.palette.text};
    text-decoration: underline;

    &:hover,
    &:active {
        color: ${({ theme }) => theme.palette.text};
    }
`;

const TruncatedStyled = styled.span``;

export { ToggleLinkStyled, TruncatedStyled };
