import styled from 'styled-components';
import { Button } from 'components/buttons';

export const CollapsableTextStyled = styled.p`
    ${({ theme }) => theme.typography.p};

    width: 100%;
    height: 100%;
    margin: 0;

    // This do the collapsable trick
    max-height: ${({ isCollapsed, maxHeightCollapsed }) =>
        isCollapsed ? maxHeightCollapsed : 'unset'};
    overflow: hidden;
`;

export const ToggleCollapsableTextStyled = styled(Button).attrs({
    variant: Button.Variant.LINK,
})`
    ${({ theme }) => theme.typography.p};

    padding: 0;
    margin: 0;
    vertical-align: baseline;
    background: transparent;
`;
