import React, { useCallback, useEffect, useRef, useState } from 'react';

import { theme } from 'theme';

import {
    CollapsableTextStyled,
    ToggleCollapsableTextStyled,
} from './CollapsableTextByMaxHeight.style';

const Constants = {
    DEFAULT_MAX_HEIGHT_COLLAPSED: `${theme.typography.p['line-height'] * 3}rem`,
};

const CollapsableTextByMaxHeight = ({
    t,
    maxHeightCollapsed = Constants.DEFAULT_MAX_HEIGHT_COLLAPSED,
    children,
}) => {
    const collapsableTextRef = useRef(null);

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [shouldShowCollapseButton, setShouldShowCollapseButton] = useState(true);

    const toggleCollapsable = useCallback(() => setIsCollapsed((oldValue) => !oldValue), []);

    useEffect(() => {
        const { clientHeight, scrollHeight } = collapsableTextRef.current;

        setShouldShowCollapseButton(clientHeight !== scrollHeight);
    }, [children]);

    return (
        <>
            <CollapsableTextStyled
                ref={collapsableTextRef}
                isCollapsed={isCollapsed}
                maxHeightCollapsed={maxHeightCollapsed}
            >
                {children}
            </CollapsableTextStyled>
            {shouldShowCollapseButton && (
                <ToggleCollapsableTextStyled onClick={toggleCollapsable}>
                    {isCollapsed
                        ? t('components_CollapsableText_readMore')
                        : t('components_CollapsableText_readLess')}
                </ToggleCollapsableTextStyled>
            )}
        </>
    );
};

export default CollapsableTextByMaxHeight;
