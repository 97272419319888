import React from 'react';

import CollapsableTextByMaxHeight from './components/CollapsableTextByMaxHeight';
import CollapsableTextByMaxCharacters from './components/CollapsableTextByMaxCharacters';

const CollapsableText = (props) => {
    const { method } = props;

    if (method === 'maxCharacters') {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <CollapsableTextByMaxCharacters {...props} />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <CollapsableTextByMaxHeight {...props} />;
};

export default CollapsableText;
