import React, { Component } from 'react';
import { length } from 'stringz';
import { ToggleLinkStyled, TruncatedStyled } from './CollapsableTextByMaxCharacters.style';
import { ExpandedState } from './CollapsableTextByMaxCharacters.constants';
import { truncateText } from './helpers';

class CollapsableTextByMaxCharacters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: ExpandedState.CLOSED,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleOpen() {
        const { onOpen } = this.props;

        this.setState(
            {
                expanded: ExpandedState.OPEN,
            },
            () => {
                if (onOpen) {
                    onOpen();
                }
            }
        );
    }

    handleClose() {
        const { onClose } = this.props;

        this.setState(
            {
                expanded: ExpandedState.CLOSED,
            },
            () => {
                if (onClose) {
                    onClose();
                }
            }
        );
    }

    renderText() {
        const { text, maxCharacters } = this.props;
        const { expanded } = this.state;

        const rawText = this.props.rawText || text;
        const rawTextLength = rawText ? rawText.length : 0;

        if (maxCharacters < rawTextLength) {
            if (expanded === ExpandedState.OPEN) {
                return this.props.renderText ? this.props.renderText() : text;
            }
            return (
                <TruncatedStyled>
                    {truncateText(rawText, maxCharacters)}
                    &hellip;
                </TruncatedStyled>
            );
        }
        return this.props.renderText ? this.props.renderText() : text;
    }

    renderCloseButton() {
        const { closeButtonText } = this.props;
        return (
            <ToggleLinkStyled
                onClick={this.handleClose}
                data-testid="CollapsableTextByMaxCharacters_toggle"
            >
                {closeButtonText}
            </ToggleLinkStyled>
        );
    }

    renderOpenButton() {
        const { alwaysShowToggle, maxCharacters, text, openButtonText } = this.props;
        const openButton = (
            <ToggleLinkStyled
                onClick={this.handleOpen}
                data-testid="CollapsableTextByMaxCharacters_toggle"
            >
                {openButtonText}
            </ToggleLinkStyled>
        );

        if (alwaysShowToggle) {
            return openButton;
        }
        if (maxCharacters < length(text)) {
            return openButton;
        }

        return null;
    }

    render() {
        const { children } = this.props;
        const { expanded } = this.state;

        const TextComponent = (
            <>
                {this.renderText()}
                {expanded === ExpandedState.CLOSED ? this.renderOpenButton() : null}
            </>
        );

        return (
            <>
                {children({ text: TextComponent })}
                {expanded === ExpandedState.OPEN ? this.renderCloseButton() : null}
            </>
        );
    }
}

CollapsableTextByMaxCharacters.defaultProps = {
    maxCharacters: 70,
    openButtonText: 'more',
    closeButtonText: 'less',
};

export default CollapsableTextByMaxCharacters;
